import React, { useEffect } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  FormGroup,
  Checkbox,
  InputLabel,
  Select,
  Button,
  Paper,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import staticData from "./edit-single-activity.json";
import "./edit-single-activity.scss";
import { TabComponent } from "../richtext/TabComponent";
import SingleFileUploader from "../file-upload/single-file-upload ";
import PlayerComponent from "../video-player/video-player";
import EditSingleStyles from "../../../styles/editSingleActivity";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";

export default function EditSingleActivity() {
  const navigate = useNavigate();

  const [sampleValue, setSampleValue] = React.useState("No");
  const [spanishValue, setSpanishValue] = React.useState("False");
  const [equipmentValue, setEquipmentValue] = React.useState("False");
  const [openModal, setOpenModal] = React.useState(false);
  const {
    types,
    subTypes,
    gradeBands,
    numberOfPlayers,
    spaceRequirements,
    standards,
    selCompetencies,
    progressionLevel,
    equipments,
  } = staticData;

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSampleValue((event.target as HTMLInputElement).value);
  };
  const handleSpanishRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSpanishValue((event.target as HTMLInputElement).value);
  };
  const handleEquipmentRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setEquipmentValue((event.target as HTMLInputElement).value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);
  return (
    <Box className="edit-single-activity" sx={EditSingleStyles.boxContainer}>
      <Paper sx={{ padding: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" sx={EditSingleStyles.editSingleHeader}>
              Edit Single Activity
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridContainer">
          <Grid item xs={2.5}>
            <Typography
              variant="body2"
              align="left"
              sx={EditSingleStyles.typoLabel}
            >
              <span className="mandatory">*</span>Name
            </Typography>
            <TextField
              id="outlined-basic"
              label="name"
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{ className: "textfield-label" }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={EditSingleStyles.gridAlign}
            className="checkbox"
          >
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.sampleLabel}
            >
              Free Sample
            </Typography>
            <FormControl
              sx={EditSingleStyles.radioLabels}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={sampleValue}
                  onChange={handleRadioChange}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={2.5}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <span className="mandatory">*</span>Type
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                Type
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Type"
              >
                <option aria-label="None" value="" />
                {types.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Dialog open={openModal} onClose={handleCloseModal}>
              <DialogTitle>Edit Type</DialogTitle>
              <DialogContent>
                <Button onClick={handleCloseModal}>Edit</Button>
                <Button onClick={handleCloseModal}>Add</Button>
                <Typography
                  variant="body2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  Type
                </Typography>
                <TextField
                  id="outlined-basic"
                  label="type"
                  size="small"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ className: "textfield-label" }}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={handleCloseModal}>Save</Button>
                <Button onClick={handleCloseModal}>Close</Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={2.5}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <span className="mandatory">*</span>Sub-Type
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                Sub-Type
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Sub-Type"
              >
                <option aria-label="None" value="" />
                {subTypes.map((subType) => (
                  <optgroup key={subType.label} label={subType.label}>
                    {subType.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={2.5}
            sx={EditSingleStyles.gridAlign}
            className="checkbox"
          >
            <Typography
              variant="subtitle2"
              sx={EditSingleStyles.checkboxLabelMargin}
            >
              <span className="mandatory">*</span>Grade Bands
            </Typography>

            <FormControl
              sx={EditSingleStyles.checkboxLabelMargin}
              component="fieldset"
              variant="standard"
              size="small"
            >
              <FormGroup row>
                {gradeBands.map((grade) => (
                  <FormControlLabel
                    key={grade}
                    control={<Checkbox size="small" name={grade} />}
                    label={<span className="formLabel">{grade}</span>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="gridContainer">
          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.labelBelowMargin}
                >
                  <span className="mandatory">*</span>No.of players
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                No. of players
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="No. of players"
              >
                <option aria-label="None" value="" />
                {numberOfPlayers.map((numPlayers) => (
                  <option key={numPlayers} value={numPlayers}>
                    {numPlayers}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.labelBelowMargin}
                >
                  <span className="mandatory">*</span>Space Requirements
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                Space Requirements
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Space Requirements"
              >
                <option aria-label="None" value="" />
                {spaceRequirements.map((spaceReq) => (
                  <option key={spaceReq} value={spaceReq}>
                    {spaceReq}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridContainer">
          <Grid
            item
            xs={3}
            sx={EditSingleStyles.gridAlign}
            className="checkbox"
          >
            <Typography
              variant="subtitle2"
              sx={EditSingleStyles.checkboxLabelMargin}
            >
              Standards Main Alignment
            </Typography>

            <FormControl
              sx={EditSingleStyles.radioLabels}
              component="fieldset"
              variant="standard"
            >
              <FormGroup row>
                {standards.map((standard) => (
                  <FormControlLabel
                    key={standard}
                    control={<Checkbox name={standard} size="small" />}
                    label={<span className="formLabel">{standard}</span>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Typography
              variant="subtitle2"
              sx={EditSingleStyles.checkboxLabelMargin}
            >
              Sub-Standards Main Alignment
            </Typography>

            <TextField
              id="outlined-basic"
              label="sub-standards"
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{ className: "textfield-label" }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={EditSingleStyles.gridAlign}
            className="checkbox"
          >
            <Typography variant="subtitle2">SEL Competencies</Typography>
            <FormControl
              sx={EditSingleStyles.radioLabels}
              component="fieldset"
              variant="standard"
            >
              <FormGroup row>
                {selCompetencies.map((selCompetence) => (
                  <FormControlLabel
                    key={selCompetence.value}
                    control={
                      <Checkbox
                        name={selCompetence.value.toString()}
                        size="small"
                      />
                    }
                    label={
                      <span className="formLabel">{selCompetence.label}</span>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridContainer">
          <Grid item xs={3}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <span className="mandatory">*</span>Progression level
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                progression level
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Progression Level"
              >
                <option aria-label="None" value="" />
                {progressionLevel.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Grid container alignItems="center">
              <Grid item xs={9}>
                <Typography
                  variant="subtitle2"
                  align="left"
                  sx={EditSingleStyles.typoLabel}
                >
                  <span className="mandatory">*</span> Skills & Concepts
                </Typography>
              </Grid>
            </Grid>
            <FormControl fullWidth size="small">
              <InputLabel
                htmlFor="grouped-native-select"
                sx={EditSingleStyles.inputLabelStyle}
              >
                Skills & Concepts
              </InputLabel>
              <Select
                native
                defaultValue=""
                id="grouped-native-select"
                label="Skills & Concepts"
              >
                <option aria-label="None" value="" />
                {progressionLevel.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridContainer">
          <Grid item xs={3}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.typoLabel}
            >
              Video English
            </Typography>
            <PlayerComponent />
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.typoLabel}
            >
              Video Spanish
            </Typography>
            <PlayerComponent />
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.typoLabel}
            >
              Diagram
            </Typography>
            <SingleFileUploader />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="gridContainer">
          <Grid item xs={3}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.typoLabel}
            >
              Inclusion Guide Cross link
            </Typography>
            <TextField
              id="outlined-basic"
              label="Inclusion Guide Cross link"
              size="small"
              variant="outlined"
              fullWidth
              InputLabelProps={{ className: "textfield-label" }}
            />
          </Grid>
          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.sampleLabel}
            >
              Has Spanish Translation
            </Typography>
            <FormControl
              sx={EditSingleStyles.radioLabels}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={spanishValue}
                  onChange={handleSpanishRadioChange}
                  row
                >
                  <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="True"
                    className="formLabel"
                  />
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="False"
                    className="formLabel"
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Typography
              variant="subtitle2"
              align="left"
              sx={EditSingleStyles.sampleLabel}
            >
              <span className="mandatory">*</span> Equipment Required ?
            </Typography>
            <FormControl
              sx={EditSingleStyles.radioLabels}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={equipmentValue}
                  onChange={handleEquipmentRadioChange}
                  row
                >
                  <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="True"
                    className="formLabel"
                  />
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="False"
                    className="formLabel"
                  />
                </RadioGroup>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3} sx={EditSingleStyles.gridAlign}>
            <Typography variant="subtitle2">Equipment</Typography>

            <FormControl
              sx={EditSingleStyles.checkboxLabelMargin}
              component="fieldset"
              variant="standard"
              size="small"
            >
              <FormGroup row>
                {equipments.map((equip) => (
                  <FormControlLabel
                    key={equip.value}
                    control={
                      <Checkbox name={equip.value.toString()} size="small" />
                    }
                    label={<span className="formLabel">{equip.label}</span>}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} className="gridContainer">
          <TabComponent value={tabValue} handleChange={handleTabChange} />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" className="centerAlign">
            Submit
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}
