const RichTextTabs = {
  tabBoxContent: {
    p: 3,
  },
  tabContent: { width: "100%" },
  tabLabels: { fontSize: "13px", minWidth: "120px" },
  boxDivider: {
    borderBottom: 1,
    borderColor: "divider",
    overflowX: "auto",
  },
  tabsBackground: {
    backgroundColor: "#E2DED0",
  },
};
export default RichTextTabs;
