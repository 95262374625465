import React, { useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import ActivitiesTable from "./pages/activities-table/ActivitiesTable";
import EditSingleActivity from "./pages/single-activity/edit-single-activity/edit-single-activity";
import EditMasterData from "./pages/masterdata/edit-master-data";
import LoaderContext from "./context/loaderContext";
import "./App.scss";
import AlertService from "./services/AlertService";
import AlertContext from "./context/alertContext";
import IAlertType from "./enums/AlertEnum";
import ThinkificUserContext from "./context/thinkificUserContext";
import Login from "./pages/Login/Login";
import AdminDetailsContext from "./context/adminDetailsContext";
import HeaderComponent from "./component/HeaderComponent";
import "./assets/styles/style.css";
import "./assets/styles/responsive.css";
import SearchContext from "./context/searchContext";
import ToggleSearchViewSingle from "./component/ToggleSearchViewSingle";

function App() {
  const [isLoader, setIsLoader] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isAlert, setIsAlert] = useState<IAlert>({
    isOpen: false,
    message: "",
    type: "",
  });

  const [thinkificUserInfo, setThinkificUserInfo] = useState<IThinkificUser>(
    {} as IThinkificUser,
  );

  const [adminDetails, setAdminDetails] = useState<IAdminDetails>({
    accessToken: "",
    username: "",
  });

  const [isAdmin, setIsAdmin] = useState(false);

  const [searchFilterDetails, setSearchFilterDetails] = useState({
    searchParams: {},
    activityList: [""],
  });

  const loaderValue = useMemo(() => {
    return { setIsLoader };
  }, [setIsLoader]);

  const successAlert = (message: string) => {
    setIsAlert({
      isOpen: true,
      message,
      type: IAlertType.SUCCESS,
    });
    setTimeout(() => {
      setIsAlert({
        isOpen: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  const errorAlert = (message: string) => {
    setIsAlert({
      isOpen: true,
      message,
      type: IAlertType.ERROR,
    });
    setTimeout(() => {
      setIsAlert({
        isOpen: false,
        message: "",
        type: "",
      });
    }, 3000);
  };

  const alertValue = useMemo(() => {
    return { successAlert, errorAlert };
  }, []);

  const adminDetailsValue = useMemo(() => {
    return {
      adminDetails,
      setAdminDetails,
    };
  }, [adminDetails, setAdminDetails]);

  const thinkificUserDetailsValue = useMemo(() => {
    return { thinkificUserInfo, setThinkificUserInfo };
  }, [thinkificUserInfo, setThinkificUserInfo]);

  const searchDetailsValue = useMemo(() => {
    return {
      searchFilterDetails:
        Object.keys(searchFilterDetails.searchParams).length !== 0 ||
        Object.keys(searchFilterDetails.activityList).length !== 0
          ? searchFilterDetails
          : { searchParams: {}, activityList: [""] },
      setSearchFilterDetails,
    };
  }, [searchFilterDetails, setSearchFilterDetails]);

  useEffect(() => {
    const getUserInfo = async () => {
      const messageEvent = (event: { origin: string; data: string }) => {
        if (
          event.origin === process.env.REACT_APP_PARENT_URL ||
          event.origin === process.env.REACT_APP_PARENT_PROD_URL
        ) {
          setThinkificUserInfo(
            JSON.parse(event.data) as unknown as IThinkificUser,
          );
          setIsLoader(false);
          setIsAdmin(false);
          setIsInitialLoad(false);
          window.removeEventListener("message", messageEvent);
        }
      };
      try {
        window.addEventListener("message", messageEvent);
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error);
        /* eslint-disable no-console */
        setIsInitialLoad(false);
      }
    };
    if (window.self !== window.top) {
      getUserInfo();
      setIsAdmin(false);
    } else {
      setIsInitialLoad(false);
      setIsAdmin(true);
    }
  }, []);

  if (isInitialLoad) {
    return (
      <Box className="loader">
        <CircularProgress />
        <div style={{ textAlign: "center", marginTop: "5px" }}>
          <div>Loading...</div>
          <div>(Refresh page if needed.)</div>
        </div>
      </Box>
    );
  }

  return (
    <div className="App">
      <LoaderContext.Provider value={loaderValue}>
        <AlertContext.Provider value={alertValue}>
          <ThinkificUserContext.Provider value={thinkificUserDetailsValue}>
            <AdminDetailsContext.Provider value={adminDetailsValue}>
              <SearchContext.Provider value={searchDetailsValue}>
                {isLoader && (
                  <Box className="loader">
                    <CircularProgress />
                  </Box>
                )}
                {isAlert.isOpen && (
                  <Box className="alert">
                    <AlertService
                      message={isAlert.message}
                      type={isAlert.type}
                    />
                  </Box>
                )}
                <Routes>
                  {isAdmin ? (
                    <>
                      <Route
                        path="/*"
                        element={
                          <>
                            <HeaderComponent />
                            <ActivitiesTable />
                          </>
                        }
                      />
                      <Route
                        path="/editSingleActivity/:id"
                        element={
                          <>
                            <HeaderComponent />
                            <EditSingleActivity />
                          </>
                        }
                      />
                      <Route
                        path="/activities"
                        element={
                          <>
                            <HeaderComponent />
                            <ToggleSearchViewSingle />
                          </>
                        }
                      />
                      <Route
                        path="/editMasterActivity"
                        element={
                          <>
                            <HeaderComponent />
                            <EditMasterData />
                          </>
                        }
                      />
                      <Route path="/login" element={<Login />} />
                    </>
                  ) : (
                    <>
                      <Route path="/*" element={<ToggleSearchViewSingle />} />
                      <Route
                        path="/editSingleActivity/:id"
                        element={<EditSingleActivity />}
                      />
                    </>
                  )}
                </Routes>
              </SearchContext.Provider>
            </AdminDetailsContext.Provider>
          </ThinkificUserContext.Provider>
        </AlertContext.Provider>
      </LoaderContext.Provider>
    </div>
  );
}

export default App;
