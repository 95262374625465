import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";

const VIDEO_PATH = "https://youtu.be/0BIaDVnYp2A";

function PlayerComponent() {
  const playerRef = useRef<ReactPlayer | null>(null);

  return (
    <Box>
      <ReactPlayer
        ref={playerRef}
        url={VIDEO_PATH}
        controls
        width="100%"
        height="100%"
      />
    </Box>
  );
}

export default PlayerComponent;
