const EditSingleStyles = {
  boxContainer: { flexGrow: 1, padding: 5, fontSize: "12px" },
  inputLabelStyle: { fontSize: "12px" },
  editSingleHeader: { mb: 3 },
  typoLabel: { mb: 1 },
  sampleLabel: { mb: 1, ml: 2 },
  radioLabels: { m: 1, ml: 2 },
  gridAlign: { textAlign: "left" },
  checkboxLabelMargin: { m: 1 },
  labelBelowMargin: { mb: 2 },
};
export default EditSingleStyles;
