import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Switch from "@mui/material/Switch";
import { Popover, Divider, Paper } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import Box from "@mui/material/Box";
import { GridDeleteForeverIcon } from "@mui/x-data-grid";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CloseIcon from "@mui/icons-material/Close";
import AdvancedSearch from "../advanced-search/advanced-search";
import SearchActivityStyles from "../../styles/searchActivity";
import {
  getAllActivities,
  getAllSavedActivities,
  getAllUserActivities,
  saveActivityForUser,
  searchActivities,
  searchUserActivities,
} from "../../APIs/activitiesApi";
import "./search-activity.scss";
import LoaderContext from "../../context/loaderContext";
import AlertContext from "../../context/alertContext";
import advancedSearchForm from "./advanced-search-form";
import { getAllMasterData } from "../../APIs/masterDataApi";
import IAdvancedSearchEnum from "../../enums/AdvancedSearchEnum";
import ILocalStorageEnum from "../../enums/LocalStorageEnum";
// import HtmlTooltip from "../../component/HTMLToolTip";
import formatString from "../../utils/stringUtils";
import SearchContext from "../../context/searchContext";
import ThinkificUserContext from "../../context/thinkificUserContext";
import isEmpty from "../../utils/ObjectIsEmpty";
import sortActivities from "../../utils/activitiesSort";

interface ISearchActivity {
  setActivityId: Dispatch<SetStateAction<string | null>>;
}

export default function SearchActivity({ setActivityId }: ISearchActivity) {
  const { setIsLoader } = useContext<ILoader>(LoaderContext);
  const location = useLocation();
  const { errorAlert } = useContext<IAlertContext>(AlertContext);
  const { thinkificUserInfo } =
    useContext<IThinkificUserDetailsContext>(ThinkificUserContext);
  const { searchFilterDetails, setSearchFilterDetails } =
    useContext<ISearchParamsContext>(SearchContext);

  const navigate = useNavigate();

  const [form, setform] = useState({ ...advancedSearchForm });
  const [initialForm, setInitialForm] = useState({ ...advancedSearchForm });
  const [isContainSearchData, setIsContainSearchData] = useState(false);
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [isDisplayUnrelated, setIsDisplayUnrelated] = useState(false);
  /* eslint-enable @typescript-eslint/no-unused-vars */
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);
  const [includeState, setIncludeState] = useState({
    video: false,
    virtual: false,
    sel: false,
    inclusion: false,
  });
  const [checkedGrade, setCheckedGrade] = useState("");
  const [isIntialCall, setIsIntialCall] = useState(false);
  const [data, setData] = useState<IActivity[]>([]);
  const [isSavedActivities, setIsSavedActivities] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [showAllActivities, setShowAllActivities] = useState(false);
  const [includedActivities, setIncludedActivities] = useState(0);
  const [notIncludedActivities, setNotIncludedActivities] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLImageElement | null>(null);
  useEffect(() => {
    if (window.self === window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    } else if (thinkificUserInfo.activityId) {
      setActivityId(thinkificUserInfo.activityId);
    }
  }, [navigate, setActivityId, thinkificUserInfo.activityId]);

  const getActivitiesList = async (isShowAllActivities?: boolean) => {
    setIsLoader(true);
    if (!isEmpty(thinkificUserInfo)) {
      const response = (await getAllUserActivities(
        errorAlert,
        (thinkificUserInfo as unknown as IThinkificUser)?.id,
        isShowAllActivities ?? false,
      )) as unknown as IThinkificUserActivity;
      if (response) {
        setIncludedActivities(response.includedActivities);
        setNotIncludedActivities(response.notIncludedActivities);
      }
      setData(sortActivities(response?.allActivities ?? []));
      setSearchFilterDetails({
        searchParams: {},
        activityList:
          response?.allActivities?.map((item: IActivity) => item._id) ?? [],
      });
    } else {
      const response = (await getAllActivities(errorAlert)) as IActivity[];
      setData(sortActivities(response ?? []));
      setSearchFilterDetails({
        searchParams: {},
        activityList: response?.map((item) => item._id) ?? [],
      });
    }
    setIsLoader(false);
  };

  const searchAllActivities = async (
    searchParams: SearchParams,
    isShowAllActivities: boolean,
  ) => {
    setIsLoader(true);
    if (!isEmpty(thinkificUserInfo)) {
      const searchUserResults = (await searchUserActivities(
        errorAlert,
        searchParams,
        thinkificUserInfo.id,
        isShowAllActivities ?? false,
      )) as unknown as IThinkificUserActivity;
      if (searchUserResults) {
        setIsContainSearchData(true);
        setIncludedActivities(searchUserResults.includedActivities);
        setNotIncludedActivities(searchUserResults.notIncludedActivities);
      }
      setData(sortActivities(searchUserResults.allActivities ?? []));
      setSearchFilterDetails({
        searchParams,
        activityList:
          searchUserResults?.allActivities?.map(
            (item: IActivity) => item._id,
          ) ?? [],
      });
    } else {
      const searchResults = (await searchActivities(
        errorAlert,
        searchParams,
      )) as IActivity[];
      if (searchResults) {
        setIsContainSearchData(true);
      }
      setData(sortActivities(searchResults ?? []));
      setSearchFilterDetails({
        searchParams,
        activityList: searchResults?.map((item) => item._id) ?? [],
      });
    }

    if (!isIntialCall) {
      setIsIntialCall(true);
    }
    setIsLoader(false);
    setIsModalOpen(false);
    setIsApiCalled(true);
  };

  const handleToggleActivities = () => {
    setShowAllActivities((prevShowAllActivities) => !prevShowAllActivities);
    if (!isEmpty(searchFilterDetails.searchParams)) {
      searchAllActivities(searchFilterDetails.searchParams, !showAllActivities);
    } else {
      getActivitiesList(!showAllActivities);
    }
  };

  useEffect(() => {
    if (Object.keys(searchFilterDetails.searchParams).length !== 0) {
      searchAllActivities(searchFilterDetails.searchParams, showAllActivities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (!isIntialCall) {
      setIsIntialCall(true);
      getActivitiesList(showAllActivities);
    }
    setIsModalOpen(false);
    setIsApiCalled(false);
  };

  const fetchMasterData = useCallback(async () => {
    const response = (await getAllMasterData(errorAlert)) || [];
    const gradeBandsOptions = response.filter(
      (item) => item.type === "gradeBands",
    );
    const formObject = JSON.parse(
      JSON.stringify(advancedSearchForm),
    ) as IAdvancedSearchFormType;

    response
      .sort((a, b) => a.value.localeCompare(b.value))
      .forEach((master: BaseResponse) => {
        const { type } = master;
        if (formObject[type]) {
          if (type === IAdvancedSearchEnum.subType) {
            formObject[type].initialList?.push(master);
            formObject[type].options?.push(master);
          } else {
            formObject[type].options?.push(master);
          }
        }
      });
    const initialFormObject = {
      ...formObject,
      gradeBands: { ...formObject.gradeBands, options: gradeBandsOptions },
    };
    setform(initialFormObject);
    setInitialForm(JSON.parse(JSON.stringify(initialFormObject)));
  }, [errorAlert]);

  useEffect(() => {
    fetchMasterData();
  }, [fetchMasterData]);

  const formChangeHandler = (
    name: string,
    value: BaseResponse | string | null | BaseResponse[] | boolean | boolean[],
    option?: string | string[],
    gradeChecked?: string,
  ) => {
    const formObject = { ...form };
    const formItemObject = formObject[name];
    if (
      [
        IAdvancedSearchEnum.name.toString(),
        IAdvancedSearchEnum.shapePEStandards.toString(),
      ].includes(name)
    ) {
      formItemObject.value = value;
    } else if (name === IAdvancedSearchEnum.type) {
      if (value) {
        formItemObject.value = value;
        formObject.subType.options = formObject.subType.initialList?.filter(
          (subtype) => subtype.reference?.includes((value as BaseResponse)._id),
        );
        formObject.subType.value = [];
      } else {
        formItemObject.value = null;
        formObject.subType.options = [
          ...(formObject.subType?.initialList || []),
        ];
        formObject.subType.value = [];
      }
      formItemObject.options = [...(formItemObject.options || [])];
    } else if (
      [
        IAdvancedSearchEnum.subType.toString(),
        IAdvancedSearchEnum.skillsAndConcepts.toString(),
        IAdvancedSearchEnum.numberOfPlayers.toString(),
        IAdvancedSearchEnum.spaceRequirement.toString(),
        IAdvancedSearchEnum.equipment.toString(),
        IAdvancedSearchEnum.progressionLevel.toString(),
        IAdvancedSearchEnum.isEquipmentRequired.toString(),
        IAdvancedSearchEnum.isSearchInSavedActivities.toString(),
      ].includes(name)
    ) {
      formItemObject.value = value;
    } else if (name === IAdvancedSearchEnum.gradeBands) {
      if (Array.isArray(value) && Array.isArray(option)) {
        formItemObject.value = formItemObject.value.filter(
          (grade: BaseResponse) => value[option.indexOf(grade.value)],
        );
        option.forEach((opt, index) => {
          if (value[index]) {
            const foundGrade = formItemObject.options?.find(
              (grade: BaseResponse) => grade.value === opt,
            );
            if (foundGrade) {
              const uniqueValues = new Set(
                formItemObject.value.map((grade: BaseResponse) => grade.value),
              );
              if (!uniqueValues.has(foundGrade.value)) {
                formItemObject.value.push(foundGrade);
                uniqueValues.add(foundGrade.value);
              }
            }
          }
        });
      } else {
        formItemObject.value = formItemObject.value.filter(
          (grade: BaseResponse) => !option || grade.value !== option,
        );
      }
      setCheckedGrade(gradeChecked || "");
    }
    formObject[name] = formItemObject;
    setform({ ...formObject });
  };

  const handleClearFilters = () => {
    setIncludeState({
      video: false,
      virtual: false,
      sel: false,
      inclusion: false,
    });
    setform(JSON.parse(JSON.stringify(initialForm)));
    setSelectedGrades([]);
  };

  useEffect(() => {}, []);

  const toggleSaved = async () => {
    if (isSavedActivities) {
      getActivitiesList(showAllActivities);
    } else {
      handleClearFilters();
      setIsContainSearchData(false);
      setIsLoader(true);

      const response = await getAllSavedActivities(
        errorAlert,
        thinkificUserInfo?.id || 0,
      );
      setData(sortActivities(response ?? []));
      setIsLoader(false);
      setSearchFilterDetails({
        searchParams: {},
        activityList: response?.map((item) => item._id) ?? [],
      });
    }
    setIsSavedActivities((isSaved: boolean) => !isSaved);
  };

  const handleSearch = async () => {
    try {
      const {
        name,
        type,
        subType,
        gradeBands,
        skillsAndConcepts,
        numberOfPlayers,
        spaceRequirement,
        equipment,
        progressionLevel,
        shapePEStandards,
        isEquipmentRequired,
        isSearchInSavedActivities,
      } = form;

      const searchParams: SearchParams = {
        name: name.value,
        type: type.value?._id,
        subType: subType.value.map((sub: BaseResponse) => sub._id),
        gradeBands: gradeBands.value.map(
          (gradeBand: BaseResponse) => gradeBand._id,
        ),
        skillsAndConcepts: skillsAndConcepts.value.map(
          (skill: BaseResponse) => skill._id,
        ),
        isEquipmentRequired: isEquipmentRequired.value,
        isSearchInSavedActivities: isSearchInSavedActivities.value,
        numberOfPlayers: numberOfPlayers.value?._id,
        spaceRequirement: spaceRequirement.value?._id,
        equipment: equipment.value?.map((equip: BaseResponse) => equip._id),
        progressionLevel: progressionLevel.value?._id,
        subStandardsAlignments: shapePEStandards.value,
        video: includeState.video,
        virtualAdaption: includeState.virtual,
        selReinforcement: includeState.sel,
        inclusionAdaptation: includeState.inclusion,
      };

      Object.keys(searchParams).forEach((key) => {
        const value = searchParams[key as keyof SearchParams];

        if (
          (Array.isArray(value) && value.length === 0) ||
          (typeof value === "boolean" && !value) ||
          value === ""
        ) {
          delete searchParams[key as keyof SearchParams];
        }
      });

      searchAllActivities(searchParams, showAllActivities);
    } catch (error) {
      errorAlert("Error performing search");
    }
  };

  const handleClearSearch = async () => {
    handleClearFilters();
    getActivitiesList(showAllActivities);
    setIsContainSearchData(false);
  };

  // const toolTipData = useCallback(() => {
  //   return (
  //     <HtmlTooltip
  //       title={
  //         <>
  //           <p>
  //             Switch on/off toggle button to see the activities related to you
  //           </p>
  //           <Switch
  //             value={isDisplayUnrelated}
  //             onChange={() => setIsDisplayUnrelated(!isDisplayUnrelated)}
  //           />
  //         </>
  //       }
  //     >
  //       <span>
  //         <i className="fa-solid fa-info" />
  //       </span>
  //     </HtmlTooltip>
  //   );
  // }, [isDisplayUnrelated]);

  const saveActivity = async (isSave: boolean, activityId: string) => {
    setIsLoader(true);

    const response = await saveActivityForUser(
      errorAlert,
      thinkificUserInfo?.id || 0,
      activityId,
      isSave,
    );

    if (response) {
      const activities = data.map((item) => {
        const activity = { ...item };
        if (activity._id === activityId) {
          if (isSave) {
            activity.isSavedActivity = true;
          } else {
            activity.isSavedActivity = false;
          }
        }
        return activity;
      });
      setData(sortActivities(activities));
    }
    setIsLoader(false);
  };
  // const gradeMappings: Record<string, string[]> = {
  //   "Pre-K": ["Pre-K"],
  //   "K-2": ["K", "1", "2"],
  //   "3-5": ["3", "4", "5"],
  //   "6-8": ["6", "7", "8"],
  //   "K-5 OST": ["K", "1", "2", "3", "4", "5"],
  //   "5-8 OST": ["5", "6", "7", "8"],
  // };
  const gradeOrder = ["Pre-K", "K", "1", "2", "3", "4", "5", "6", "7", "8"];
  const includeCheckboxes = [
    { label: "Video Demo", name: "video" },
    { label: "Virtual Adaptation", name: "virtual" },
    { label: "SEL Reinforcement", name: "sel" },
    { label: "Inclusion Guidance", name: "inclusion" },
  ];

  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {!isEmpty(thinkificUserInfo) && (
        <Box sx={SearchActivityStyles.savedActivity}>
          <IconButton
            sx={SearchActivityStyles.savedActivityIcon}
            aria-label="favorite"
            onClick={toggleSaved}
          >
            {isSavedActivities ? (
              <StarIcon sx={SearchActivityStyles.starIcon} />
            ) : (
              <StarBorderIcon sx={SearchActivityStyles.starIcon} />
            )}{" "}
            SAVED ACTIVITIES
          </IconButton>
        </Box>
      )}
      <div className="topHome">
        <Container>
          <Grid container spacing={{ md: 3 }} alignItems="center">
            <Grid item md={6} xs={12} sm={8}>
              <div className="leftActivity">
                {!isModalOpen && (
                  <>
                    {" "}
                    <h3 className="small_heading fontWeight800">
                      {data.length}
                      {[0, 1].includes(data?.length ?? 0)
                        ? " Activity"
                        : " Activities"}{" "}
                      found&nbsp;
                      <div
                        role="button"
                        tabIndex={0}
                        aria-describedby={id}
                        aria-label="Information"
                        title="Information"
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        style={{
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          alt="Info"
                          src="/assets/icons/info.svg"
                          className="infoIconSTyle"
                        />
                      </div>
                      {/* {toolTipData()} */}
                    </h3>
                    {isApiCalled && (
                      <p className="title">
                        {Object.entries(form)
                          .filter(([key, value]) => {
                            const formItem =
                              value as unknown as IAdvancedSearchFormType;
                            return (
                              key !== "formItemObject" &&
                              (Array.isArray(formItem?.value)
                                ? formItem?.value?.length
                                : formItem?.value)
                            );
                          })
                          .map(([key, value]) => {
                            const formItem =
                              value as unknown as IAdvancedSearchFormType;
                            let label = formatString(key);
                            let displayValue;
                            if (key === "name") {
                              label = "Activity Name";
                              displayValue = formItem?.value;
                            } else if (key === "gradeBands") {
                              label = "Grades";
                              const checkedGradeArray = checkedGrade
                                .toString()
                                .split(",");
                              const sortedCheckedGradeArray =
                                checkedGradeArray.sort((a, b) => {
                                  return (
                                    gradeOrder.indexOf(a) -
                                    gradeOrder.indexOf(b)
                                  );
                                });
                              const sortedCheckedGrade =
                                sortedCheckedGradeArray.join(", ");
                              displayValue = sortedCheckedGrade;
                            } else if (key === "isEquipmentRequired") {
                              displayValue = formItem?.value && "Yes";
                            } else if (key === "isSearchInSavedActivities") {
                              label = "Saved Activities";
                              displayValue = formItem?.value && "Yes";
                            } else {
                              label = formatString(key);
                              if (Array.isArray(formItem?.value)) {
                                displayValue = formItem?.value
                                  .map((item) => item.value)
                                  .join(", ");
                              } else if (typeof formItem?.value === "string") {
                                displayValue = formItem?.value;
                              } else {
                                displayValue = formItem?.value.value;
                              }
                            }
                            return `${label}: ${displayValue}`;
                          })
                          .join(" | ")}
                        {Object.entries(includeState)
                          .filter(([, checked]) => checked)
                          .map(([name]) => {
                            const checkboxItem = includeCheckboxes.find(
                              (checkbox) => checkbox.name === name,
                            );
                            return (
                              <span key={name}>
                                {" "}
                                | {checkboxItem ? checkboxItem.label : name}:
                                Yes{" "}
                              </span>
                            );
                          })}
                      </p>
                    )}
                  </>
                )}
              </div>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              sm={4}
              sx={{ textAlign: { xs: "center" } }}
            >
              <div className="rightActivity">
                {isContainSearchData && (
                  <Box sx={SearchActivityStyles.modalBox}>
                    <Typography
                      variant="button"
                      sx={SearchActivityStyles.modalLabels}
                      onClick={handleClearSearch}
                    >
                      <GridDeleteForeverIcon
                        sx={SearchActivityStyles.editIcon}
                      />
                      Clear Search{" "}
                    </Typography>
                  </Box>
                )}
                <Button
                  className="btnSearch"
                  onClick={isModalOpen ? closeModal : openModal}
                >
                  <CreateRoundedIcon /> <span>Modify Search</span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Container>
        {isModalOpen && (
          <AdvancedSearch
            open={isModalOpen}
            onClose={closeModal}
            form={form}
            formChangeHandler={formChangeHandler}
            includeState={includeState}
            setIncludeState={setIncludeState}
            handleClearFilters={handleClearFilters}
            handleSearch={handleSearch}
            selectedGrades={selectedGrades}
            setSelectedGrades={setSelectedGrades}
            thinkificUserInfo={thinkificUserInfo}
          />
        )}
      </div>
      <section>
        <Container>
          <Grid container spacing={{ md: 4, sm: 3 }}>
            {data?.map((item) => (
              <Grid item xs={12} sm={4} md={4} key={item._id}>
                <Link
                  to={location.pathname}
                  onClick={() => {
                    if (item.isRelatedToUser === true) {
                      setActivityId(item._id);
                    }
                  }}
                >
                  <div
                    className="itemList"
                    style={{
                      background:
                        item.isRelatedToUser === false ? "#dadada" : "#fff",
                      cursor:
                        item.isRelatedToUser === false ? "default" : "pointer",
                    }}
                  >
                    <div className="itemHeader">
                      <h4 className="sub_heading text_primary fontHeading">
                        {item.isRelatedToUser === false ? (
                          <div>{item.name}</div>
                        ) : (
                          <div>{item.name}</div>
                        )}
                      </h4>
                      {!isEmpty(thinkificUserInfo) &&
                        item.isRelatedToUser === true &&
                        (!item.isSavedActivity ? (
                          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                          <i
                            className="far fa-star"
                            onKeyDown={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(true, item._id);
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(true, item._id);
                            }}
                          />
                        ) : (
                          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
                          <i
                            className="fa fa-star saved"
                            onKeyDown={() => saveActivity(false, item._id)}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveActivity(false, item._id);
                            }}
                          />
                        ))}
                    </div>
                    <div className="typeList">
                      <Typography component="div">
                        <Box
                          className="text_primary title"
                          sx={{ textTransform: "uppercase", fontWeight: 600 }}
                        >
                          Skills & Concepts
                        </Box>
                        <ul className="skillsConcepts">
                          {item.skillsAndConcepts
                            .sort((a, b) => a.value.localeCompare(b.value))
                            .map((skill) => (
                              <li key={skill.value}>{skill?.value}</li>
                            ))}
                        </ul>

                        <div className="borderBottom">
                          <Box
                            className="text_primary title topMargin"
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: 600,
                            }}
                          >
                            TYPE / SUBTYPE
                          </Box>
                        </div>
                        <div
                          className="subType textcolor"
                          style={
                            {
                              "--card-bg-color": item.type?.color || "#855fa7",
                            } as React.CSSProperties & {
                              [key: string]: string;
                            }
                          }
                        >
                          {item.type?.value} <br />
                          {item.subType?.value}
                        </div>
                        {/* <div>
                            <Box
                              className="text_primary title topMargin"
                              sx={{
                                textTransform: "uppercase",
                                fontWeight: 600,
                              }}
                            >
                              grades
                            </Box>
                          </div>
                          <div className="numberList">
                            <ul>
                              {item.gradeBands
                                .flatMap(
                                  (grade) => gradeMappings[grade?.value] || [],
                                )
                                .filter(
                                  (value, index, self) =>
                                    self.indexOf(value) === index,
                                )
                                .sort(
                                  (a, b) =>
                                    gradeOrder.indexOf(a) -
                                    gradeOrder.indexOf(b),
                                )
                                .map((label) => (
                                  <li key={label}>{label}</li>
                                ))}
                            </ul>
                          </div> */}
                        <div className="soloItem">
                          <div className="soloColumn">
                            <div className="borderBottom">
                              <Box
                                className="text_primary title"
                                sx={{
                                  textTransform: "uppercase",
                                  fontWeight: 600,
                                }}
                              >
                                # of players
                              </Box>
                            </div>
                            <p>{item.numberOfPlayers?.value}</p>
                          </div>
                          <div className="soloColumn">
                            <div className="borderBottom">
                              <Box
                                className="text_primary title"
                                sx={{
                                  textTransform: "uppercase",
                                  fontWeight: 600,
                                }}
                              >
                                SPACE
                              </Box>
                            </div>
                            <p>{item.spaceRequirement?.value}</p>
                          </div>
                        </div>
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
            ))}
          </Grid>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            classes={{ paper: "popover-custom" }}
          >
            <IconButton
              sx={{
                position: "absolute",
                top: "2px",
                right: "7px",
                color: "#adacb4",
                width: "20px",
              }}
              onClick={handleClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
            <Paper sx={{ p: 3, width: "100%", maxWidth: 300 }}>
              <Typography
                sx={{
                  p: 2,
                  fontFamily: "Montserrat",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#669400",
                    fontWeight: 600,
                    marginTop: "12px",
                    marginRight: "5px",
                  }}
                >
                  <CheckCircleIcon
                    sx={{ position: "relative", top: "-25px" }}
                  />
                </span>
                <span>
                  <span style={{ color: "#669400", fontWeight: 600 }}>
                    {includedActivities} activities
                    <br />
                  </span>{" "}
                  included with your catch.org subscriptions
                </span>
              </Typography>

              <Divider />
              <Typography
                sx={{
                  p: 2,
                  fontFamily: "Montserrat",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    color: "#7c7e82",
                    fontWeight: 600,
                    marginTop: "12px",
                    marginRight: "5px",
                  }}
                >
                  {" "}
                  <CancelIcon sx={{ position: "relative", top: "-58px" }} />
                </span>
                <span>
                  <span style={{ color: "#56575a", fontWeight: 600 }}>
                    {notIncludedActivities} activities
                    <br />
                  </span>{" "}
                  not included with your catch.org subscriptions
                  <br /> <br />
                  <i>
                    (See{" "}
                    <a
                      href="https://letsgo.catch.org/pages/pricing"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Pricing Page
                    </a>{" "}
                    for more information)
                  </i>
                </span>
              </Typography>
              <Divider />
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                {showAllActivities ? (
                  <ToggleOnIcon
                    sx={{ color: "#0063AB", height: "50px", width: "55px" }}
                    onClick={handleToggleActivities}
                  />
                ) : (
                  <ToggleOffIcon
                    sx={{ color: "#D3D3D3", height: "50px", width: "55px" }}
                    onClick={handleToggleActivities}
                  />
                )}
                <Typography
                  sx={{
                    color: "#0063AB",
                    fontWeight: 600,
                    fontFamily: "Oswald",
                    marginTop: "12px",
                    marginLeft: "2px",
                    letterSpacing: "1px",
                  }}
                >
                  SHOW ALL ACTIVITIES
                </Typography>
              </div>
            </Paper>
          </Popover>
        </Container>
      </section>
    </>
  );
}
