import React, { useState } from "react";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import {
  CloudUpload,
  Done,
  Error,
  CloudUploadOutlined,
} from "@mui/icons-material";

const Result: React.FC<{ status: string }> = ({ status }) => {
  return (
    <Typography variant="body1" color="textPrimary">
      {status === "success" && <Done color="success" />}
      {status === "fail" && <Error color="error" />}
      {status === "uploading" && <CloudUploadOutlined color="primary" />}
    </Typography>
  );
};

const SingleFileUploader: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [status, setStatus] = useState<
    "initial" | "uploading" | "success" | "fail"
  >("initial");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setStatus("initial");
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      setStatus("uploading");
      const formData = new FormData();
      formData.append("file", file);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={2} style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label htmlFor="file">
              <input
                id="file"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
              >
                Choose a file
              </Button>
            </label>
          </Grid>
          {file && (
            <Grid item xs={12}>
              <Typography variant="body1" color="textPrimary">
                File details:
              </Typography>
              <ul>
                <li>Name: {file.name}</li>
                <li>Type: {file.type}</li>
                <li>Size: {file.size} bytes</li>
              </ul>
            </Grid>
          )}
          {file && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                Upload a file
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Result status={status} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default SingleFileUploader;
