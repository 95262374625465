import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box, Paper } from "@mui/material";
import RichTextEditor from "./RichTextEditor";
import RichTextTabs from "../../../styles/richTextTabs";

export function CustomTabPanel({
  children,
  value,
  index,
}: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={RichTextTabs.tabBoxContent}>
          <Typography variant="subtitle1">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.defaultProps = {
  children: null,
};

export function TabComponent({
  value,
  handleChange,
}: {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}) {
  const tabLabels = [
    "Equipment Description",
    "Organization",
    "Description",
    "Teaching Suggestion",
    "Variations",
    "Differentiations",
    "Sel Reinforcement",
    "Inclusion Adaptation",
    "Virtual Adaptation",
  ];

  return (
    <Box sx={RichTextTabs.tabContent}>
      <Paper>
        <Box sx={RichTextTabs.boxDivider}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={RichTextTabs.tabsBackground}
          >
            {tabLabels.map((label) => (
              <Tab key={label} label={label} sx={RichTextTabs.tabLabels} />
            ))}
          </Tabs>
        </Box>
        {tabLabels.map((label, index) => (
          <CustomTabPanel key={label} value={value} index={index}>
            <RichTextEditor />
          </CustomTabPanel>
        ))}
      </Paper>
    </Box>
  );
}
